import React, { useState, useRef } from 'react';
import Link from 'next/link';
import { toAbsoluteUrl } from '../../utils/toAbsoluteUrl';
import SVG from "react-inlinesvg";
import cookie from 'js-cookie';
import dynamic from 'next/dynamic'   
import { ACCEPT_COOKIES, MY_LOCATION_PLACE } from '../../helpers/constTypes'    
const CookiePolicyCheckerModal = dynamic(() => import('../../components/Modals/CookiePolicyChecker'))
import Swal from 'sweetalert2'


const Footer = () => {

    React.useEffect(() => {
        setTimeout(() => {
            if(!cookie.get(ACCEPT_COOKIES)){
                setCookiePolicyChecker(true)
            }
        }, 5000);
    }, []);

    const [ cookiePolicyChecker, setCookiePolicyChecker ] = useState(false)
    const inputEl = useRef(null);

    const [ actionsLoading, setActionsLoading ] = useState(false)

    const getLocationByIp = async () => {
        let response = await fetch('https://www.iplocate.io/api/lookup?apikey=d7e6797fb909933d8d3c9cab7dc0620c')
        let parsedData = await response.json()
        return parsedData
    };


    const subscribe = async (e) => {
        e.preventDefault();
        setActionsLoading(true)
        let loc = await getLocationByIp()
        const res = await fetch('/api/subscribe', {
            body: JSON.stringify({
                email: inputEl.current.value,
                "merge_fields": {
                    "FNAME": "$user_fname",
                    "LNAME": "$user_lname",
                    // "BIRTHDAY": "$user_birthday",
                    "ADDRESS": {
                           "addr1": "---",
                           "city":  loc.city,
                           "state": loc.subdivision,
                           "zip":  loc.postal_code,
                
                     }
                  }
            }),
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST'
        });
        if (res.status === 400) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'This email is already signed up.',
            })
            setActionsLoading(false)
            return;
        }
      
        inputEl.current.value = '';
        setActionsLoading(false)
        Swal.fire(
            'Thanks for signing up for the Weedzly newsletter!',
        )
    }
    

    return (
        <>
            <footer className="footer-area">

                {
                    cookiePolicyChecker && <CookiePolicyCheckerModal
                        onClick={()=>{setCookiePolicyChecker(false)}}
                        active={ cookiePolicyChecker ? 'active' : '' }
                    />
                }

                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <h3>About Weedzly</h3>
                                <div className="about-the-store">
                                    {/* <p>We are one of the most popular online cannabis product providers. So, if you’re looking for reliability, positivity and satisfaction in terms of experience, then you’ve come to the right place.</p> */}
                                    <ul className="customer-support">
                                    <li>
                                    <Link prefetch={false}  href="/[business]" as="/dispensaries">
                                            Dispensaries
                                        </Link>
                                    </li>
                                
                                    
                                            <li className={cookie.get('IS_DELIVERY_AVAILABLE') === 'true' ? 'd-none' : ''}>
                                                <Link prefetch={false} href="/[business]" as="/deliveries">
                                                    Deliveries
                                                </Link>
                                            </li>
                                        
                                    

                                    
                                    <li>
                                        <Link prefetch={false} href="/brands">
                                            Brands
                                        </Link>
                                    </li>
                                    <li>
                                        <Link prefetch={false} href="/strains">
                                            Strains
                                        </Link>
                                    </li>
                                    <li>
                                        <Link prefetch={false} href="/library">
                                            Library
                                        </Link>
                                    </li>
                                    <li>
                                            <Link prefetch={false} href="/about">
                                                About us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link prefetch={false} href="/contact">
                                                Contact
                                            </Link>
                                        </li>
                                        <li>
                                            <Link prefetch={false} href="/privacy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link prefetch={false} href="/terms">
                                                Terms of Service
                                            </Link>
                                        </li>
                                        
                                        <li>
                                            <Link prefetch={false} href="/cookie">
                                                Cookie Policy
                                            </Link>
                                        </li>
                                        
                                    </ul>
                                   
                                </div>
                            </div>
                        </div>
                     
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <h3>Partner with us</h3>
                                <ul className="customer-support">
                                    <li>
                                        <a href="https://enterprise.weedzly.com">
                                            Dispensaries
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://enterprise.weedzly.com/deliveries">
                                            Deliveries
                                        </a>
                                    </li>

                                    <li>
                                        <a href="https://enterprise.weedzly.com/brands">
                                            Brands
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://enterprise.weedzly.com/register">
                                            Add Your Business
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                       
                       
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <h3>Weed Dispensary</h3>
                                <ul className="customer-support">
                                    <li>
                                        <Link href={`/[business]/in`} as={`/dispensaries/in`}>
                                            Weed Dispensary Near Me
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link href={`/[business]/in/[state]/[city]`} as={`/dispensaries/in/ca/north-hollywood`}>
                                            Weed Dispensary in North Hollywood
                                        </Link>
                                    </li>

                                    <li>
                                        <Link href={`/[business]/in/[state]/[city]`} as={`/dispensaries/in/ca/studio-city`}>
                                            Weed Dispensary in Studio City
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href={`/[business]/in/[state]/[city]`} as={`/dispensaries/in/ca/sun-valley`}>
                                            Weed Dispensary in Sun Valley 
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href={`/[business]/in/[state]/[city]`} as={`/dispensaries/in/ca/sherman-oaks`}>
                                            Weed Dispensary in Sherman Oaks 
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href={`/[business]/in/[state]/[city]`} as={`/dispensaries/in/ca/sunland`}>
                                            Weed Dispensary in Sunland
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href={`/[business]/in/[state]/[city]`} as={`/dispensaries/in/ca/granada-hills`}>
                                            Weed Dispensary in Granada Hills
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href={`/[business]/in/[state]/[city]`} as={`/dispensaries/in/ca/van-nuys`}>
                                            Weed Dispensary in Van Nuys 
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href={`/[business]/in/[state]/[city]`} as={`/dispensaries/in/ca/panorama-city`}>
                                            Weed Dispensary in Panorama City
                                        </Link>
                                    </li> */}
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <h3>Weed Delivery</h3>
                                <ul className="customer-support">
                                <li>
                                        <Link href={`/[business]/in`} as={`/dispensaries/in`}>
                                            Weed Delivery Near Me
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link href={`/[business]/in/[state]/[city]`} as={`/deliveries/in/ca/north-hollywood`}>
                                            Weed Delivery in North Hollywood
                                        </Link>
                                    </li>

                                    <li>
                                        <Link href={`/[business]/in/[state]/[city]`} as={`/deliveries/in/ca/studio-city`}>
                                            Weed Delivery in Studio City
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href={`/[business]/in/[state]/[city]`} as={`/deliveries/in/ca/sun-valley`}>
                                            Weed Delivery in Sun Valley 
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href={`/[business]/in/[state]/[city]`} as={`/deliveries/in/ca/sherman-oaks`}>
                                            Weed Delivery in Sherman Oaks 
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href={`/[business]/in/[state]/[city]`} as={`/deliveries/in/ca/sunland`}>
                                            Weed Delivery in Sunland
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href={`/[business]/in/[state]/[city]`} as={`/deliveries/in/ca/granada-hills`}>
                                            Weed Delivery in Granada Hills
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href={`/[business]/in/[state]/[city]`} as={`/deliveries/in/ca/van-nuys`}>
                                            Weed Delivery in Van Nuys 
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href={`/[business]/in/[state]/[city]`} as={`/deliveries/in/ca/panorama-city`}>
                                            Weed Delivery in Panorama City
                                        </Link>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="footer-bottom-area">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <p>
                                    Copyright <i className='bx bx-copyright'></i>{new Date().getFullYear()} Weedzly Inc. All Rights Reserved.
                                </p>
                            </div>
                            <div className="col-lg-6 col-md-6 single-footer-widget mb-0 ">
                                
                                <ul className="social-link d-flex align-items-center justify-content-end">

                                    <li>
                                        <Link aria-label='See more on Twitter' alt='See more on Twitter' className="d-block" target="_blank" rel="noopener noreferrer" prefetch={false} href="https://twitter.com/weedzlyofficial">
                                          
                                                <i className='bx bxl-twitter pt-0'></i>
                                            
                                        </Link>
                                    </li>
                                    <li>
                                        <Link aria-label='See more on instagram' alt='See more on instagram' className="d-block" target="_blank" rel="noopener noreferrer" prefetch={false} href="https://www.instagram.com/weedzlyofficial">
                                            
                                                <i className='bx bxl-instagram pt-0'></i>
                                         
                                        </Link>
                                    </li>
                                    

                                    <li>
                                        <Link aria-label='See more on Tiktok' alt='See more on Tiktok' className="d-block" target="_blank" rel="noopener noreferrer" prefetch={false} href="https://www.tiktok.com/@weedzlyofficial">
                                          
                                                <SVG
                                                    height={14}
                                                    width={14}

                                                    src={toAbsoluteUrl("/static/icons/tiktok.svg")}
                                                    style={{marginTop:'2px'}}
                                                />
                                          
                                        </Link>
                                    </li>
                                    <li>
                                        <Link aria-label='See more on Facebook' alt='See more on Facebook' className="d-block" target="_blank" rel="noopener noreferrer" prefetch={false} href="https://www.facebook.com/OfficialWeedzly/">
                                           
                                                <i className='bx bxl-facebook pt-0'></i>
                                          
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="lines">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div> */}
            </footer>
        </>
    );
    
}

export default Footer